import { useState, useEffect, useRef } from "preact/hooks";
import ClipboardButton from "./ClipboardButton.tsx"; // Import ClipboardButton component

interface CodeBlockProps {
  code: string;
  language: string;
  noCode?: boolean;
}

export default function CodeBlock({ code, language, noCode = false }: CodeBlockProps) {
  const codeRef = useRef<HTMLElement>(null);
  const [highlightedCode, setHighlightedCode] = useState<string>(""); // For storing the highlighted code
  const [fontSize, setFontSize] = useState<string>("14px"); // Default font size
  const [progress, setProgress] = useState<number>(0); // Progress state to track highlighting progress

  // Define a fallback for requestIdleCallback using setTimeout
  const idleCallback = (window as any).requestIdleCallback || function (cb: any) { setTimeout(cb, 0); };

  useEffect(() => {
    const chunkSize = 500; // Process in chunks of 1000 characters
    let currentIndex = 0;
    let tempHighlightedCode = ""; // Temporarily hold the highlighted code to avoid frequent state updates

    const Prism = (window as any).Prism;
    if (!Prism) {
      console.error("PrismJS is not loaded.");
      return;
    }

    const grammar = Prism.languages[language] || Prism.languages.javascript;

    // Highlight multiple chunks at once to reduce the number of idle callbacks
    function processChunks() {
      const start = currentIndex;

      // Process up to 5 chunks at once to reduce the number of updates while showing progress
      for (let i = 0; i < 5 && currentIndex < code.length; i++) {
        const chunk = code.slice(currentIndex, currentIndex + chunkSize);
        tempHighlightedCode += Prism.highlight(chunk, grammar, language);
        currentIndex += chunkSize;
      }

      // Update the highlighted code after processing 5 chunks
      setHighlightedCode((prev) => prev + tempHighlightedCode);
      tempHighlightedCode = ""; // Reset the temp holder

      // Update progress state
      const newProgress = (currentIndex / code.length) * 100;
      setProgress(Math.min(newProgress, 100));

      if (currentIndex < code.length) {
        // Schedule the next batch of chunks in an idle callback or fallback
        idleCallback(processChunks);
      } else {
        // Ensure the progress bar reaches 100% once highlighting is complete
        setProgress(100);
      }
    }

    // If the code is smaller than chunkSize, directly highlight it
    if (code.length <= chunkSize) {
      const highlighted = Prism.highlight(code, grammar, language);
      setHighlightedCode(highlighted);
      setProgress(100);
    } else {
      idleCallback(processChunks); // Start processing chunks using idle callback or fallback
    }
  }, [code, language]);

  // Handle window resize to dynamically adjust the font size
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = globalThis.innerWidth;

      if (screenWidth < 640) {
        setFontSize("10px");
      } else if (screenWidth < 768) {
        setFontSize("12px");
      } else if (screenWidth < 1024) {
        setFontSize("14px");
      } else {
        setFontSize("16px");
      }
    };

    handleResize();
    globalThis.addEventListener("resize", handleResize);

    return () => {
      globalThis.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div class="relative pt-0">
      {/* Fortschrittsbalken 
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "4px",
          backgroundColor: "#4caf50",
          width: `${progress}%`,
          transition: "width 0.1s ease",
        }}
      />*/}

      {/* Clipboard Button to copy code to clipboard */}
      <ClipboardButton text={code} />

      {!noCode && (
        <pre class="bg-black text-white overflow-auto scrollbar-always pt-0 max-h-[50vh]" style={{ fontSize }}>
          <code ref={codeRef} class={`language-${language}`} dangerouslySetInnerHTML={{ __html: highlightedCode }} />
        </pre>
      )}
    </div>
  );
}
