import { useState, useEffect } from "preact/hooks";
import Test from "../components/Test.tsx";
import { urlSignal, anchorSignal } from "./signalStore.ts"; // Importiere die Signale



interface Step {
  request: {
    method: string;
    url: string;
    headers: { [key: string]: string };
    body: string;
  };
  response: {
    status: number;
    statusText: string;
    headers: { [key: string]: string };
    body: string;
  };
  assertions: {
    type: string;
    expected: string | number | boolean | object | null;
    actual: string | number | boolean | object | null;
    passed: boolean;
    message: string;
    line: number;
  }[];
  duration: number;
}

interface TestProps {
  name: string;
  steps: Step[];
  result: string;
  sourceCode: string;
  lineOffset: number;
  duration: number;
}

interface TestSuiteProps {
  name: string;
  duration: number;
  tests: TestProps[];
  showPassed: boolean;
  urlMap?: string;  // Optional URL map parameter
  onLinkClick?: (url: string, anchor?: string) => void;  // Optionaler Link-Click Handler
}

export default function TestSuite({ name, duration, tests, showPassed, urlMap, onLinkClick }: TestSuiteProps) {
  const [urlMapData, setUrlMapData] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchUrlMap = async () => {
      try {
        const apiUrl = urlMap?.startsWith("http")
          ? urlMap
          : `/api/data?file=${encodeURIComponent(urlMap || "")}`;
  
        const response = await fetch(apiUrl);
        if (response.status === 404) {
          console.log("URL map file not found.");
          return;
        }
  
        if (!response.ok) {
          return;
        }
  
        const data = await response.json();
        setUrlMapData(data);
      } catch (_error) {
        // Fehler still behandeln
      }
    };
  
    if (urlMap) fetchUrlMap();
  }, [urlMap]);
  
  const filteredTests = showPassed ? tests : tests.filter((test) => test.result !== "pass");

  const findMatchingKey = (testSuiteName: string) => {
    for (const key in urlMapData) {
      if (testSuiteName.includes(key)) {
        return { key, link: urlMapData[key] };
      }
    }
    return null;
  };

  const match = findMatchingKey(name);

  let lastClickedLink = "";  // To track the last clicked link

  const handleLinkClick = (e: MouseEvent, link: string) => {
      e.preventDefault();
  
      const anchor = link.includes("#") ? link.split("#")[1] : undefined;
  
      // Check if the clicked link is the same as the last clicked link
      if (link === lastClickedLink) {
          // Same link clicked again - reset the signals first
          urlSignal.value = "";
          anchorSignal.value = "";
  
          // Wait for the signals to reset, then scroll smoothly
          setTimeout(() => {
              if (anchor) {
                  const element = document.getElementById(anchor);
                  if (element) {
                      element.scrollIntoView({ behavior: "smooth" });  // Smooth scroll
                  }
              }
  
              // Update the signals with the new link values
              urlSignal.value = link;
              anchorSignal.value = anchor || "";
          }, 10); // Adjust delay as necessary
      } else {
          // Different link clicked - dynamically scroll to mimic dragging scrollbar
          if (anchor) {
              const element = document.getElementById(anchor);
              if (element) {
                  const elementTop = element.getBoundingClientRect().top + globalThis.scrollY;
  
                  // Mimic smooth scroll by manually adjusting the scroll position
                  globalThis.scrollTo({
                      top: elementTop,
                      behavior: 'smooth'  // Smooth automatic scrolling
                  });
              }
          }
  
          // Update the signals with the new link values immediately
          urlSignal.value = link;
          anchorSignal.value = anchor || "";
      }
  
      // Update the last clicked link to track the most recent click
      lastClickedLink = link;
  
      if (onLinkClick) {
        onLinkClick(link, anchor);
      }
  };
  

  return (
    <div class="mb-8 space-y-2">
      <h2 class="text-2xl font-semibold">
        {match ? (
          <>
            <a
              href={match.link}
              class="text-blue-600 hover:text-blue-800"
              onClick={(e) => handleLinkClick(e, match.link)}
            >
              {match.key}
            </a>{" "}
            {name.replace(match.key, "")}
          </>
        ) : (
          name
        )}
      </h2>

      <div class="flex items-center space-x-4">
        {/*<span>Duration: {duration.toFixed(1)} ms</span>*/}

        <span class={`text-green-600 ml-3 ${!showPassed ? "line-through" : ""}`}>
          ✅ {tests.filter((test) => test.result === "pass").length} Passed
        </span>
        <span class="text-red-600">
          ❌ {tests.filter((test) => test.result === "fail").length} Failed
        </span>
      </div>

      {filteredTests.map((test) => (
        <Test key={test.name} {...test} />
      ))}
    </div>
  );
}
